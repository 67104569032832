<template>
  <section class="block newsfeed general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </section>
</template>
<script>
import Axios from 'axios';
import CommonTable from '../common/CommonTable.vue';
import CommonTitle from '../common/CommonTitle.vue';
export default {
  name: "NewsfeedMain",
  components:{ CommonTitle, CommonTable },
  data() {
    return {
      options_title: {
        name: 'Новости',
        id: 'newsfeed',
        actions: '',
      },
      options_table: {
        id: 'newsfeed',
        request: 'newsfeed',
        actions: 'edit-remove',
        ths: [
          { id: 'published', name: 'Дата', width: '6%', value: '', sort: true, search: true },
          { id: 'title', name: 'Заголовок', width: '6%', value: '', sort: true, search: true },
          { id: 'content', name: 'Заголовок', width: '6%', value: '', sort: true, search: true },
          { id: 'status_id', name: 'Статус', width: '3%', value: '', sort: true, search: true },
          { id: 'user_category', name: 'Категория', width: '3%', value: '',sort: true, search: true},
          { id: '', name: '', width: '3%', value: '',},
        ],
        table: {
          variables: ['published', 'title', 'content', 'status_id', 'user_category'],
          page: 0,
          limit: 15,
          total: 0,
          data: null
        }
      }
    }
  },
  mounted() {
    Axios
        .get(`/api/newsfeed`, {
          headers: {
            token: this.$store.getters.token
          },
          params: {
            limit: 15,
            page: 0,
          }
        })
        .then(res => {
          this.options_table.table.data = res.data.data;
          this.options_table.table.page = 1;
          this.options_table.table.limit = 15;
          this.options_table.table.total = res.data.total;
        })
        .catch(err => {
          console.error(err.response);
        })
  },

}
</script>

<style scoped>

</style>