<template>
  <NewsfeedMain/>
</template>

<script>
import NewsfeedMain from "@/components/newsfeed/NewsfeedMain.vue";
export default {
  name: "Newsfeed",
  components: {NewsfeedMain}
}
</script>

<style scoped>

</style>